<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <table-box-order
      :columns="columns"
      :rows="offers"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :filterOptions="filterOptions"
      :searchItems="searchItems"
      :showToolbar="true"
      :showPagination="false"
      :pagination="pagination"
      addText="Create new Offer"
      emptyText="No Offers found. Please add new Offer."
      @add="onAdd"
      @edit="onEdit"
      @delete="onDelete"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </table-box-order>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import router from '@/router';
import TableBoxOrder from '../../components/TableBoxOrder.vue';

export default {
  name: 'Role',
  components: { TableBoxOrder },
  metaInfo() {
    return {
      title: 'PD Offers',
      meta: [
        {
          name: 'description',
          content: `Manage Role`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listOffers({
        query: {
          ...this.$route.query,
          startdate: moment().subtract(2, 'month').format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listOffers({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listOffers({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'PD Offers',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'index',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Offer Name',
          class: { 'text-center': true },
          textKey: 'name',
          width: '20%'
        },
        {
          type: 'string',
          headerText: 'Active',
          class: { 'text-center': true },
          textKey: 'active',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Start Date',
          class: { 'text-center': true },
          textKey: 'startDate',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'End Date',
          class: { 'text-center': true },
          textKey: 'endDate',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Region',
          class: { 'text-center': true },
          textKey: 'regions',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Cluster',
          class: { 'text-center': true },
          textKey: 'clusters',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeids',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Formats',
          class: { 'text-center': true },
          textKey: 'formats',
          width: '9%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '10%',
          functions: {
            edit: true,
            delete: false
          }
        }
      ],
      searchItems: ['Store Id', 'Offer Name'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Active-True', text: 'Active-True' },
        { value: 'Active-False', text: 'Active-False' }
      ]
    };
  },
  computed: {
    ...mapState('pdOffer', ['loading', 'baseUrl', 'offers', 'pagination'])
  },
  methods: {
    ...mapActions('pdOffer', ['listOffers', 'deleteOne']),
    onAdd() {
      router.push('/offer/new');
    },
    onEdit({ row }) {
      router.push(`/offer/${row.id}`);
    },
    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',

        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    }
  }
};
</script>
